"use strict";

const autosize = require('autosize/dist/autosize')

function removeNotification() {
  $('.notification').fadeOut(500);
}

document.addEventListener('turbolinks:load', function () {
  setTimeout(removeNotification, 2000);

  autosize($('textarea'));
  
  var elmnt = document.getElementById("error_explanation");
  if (elmnt) { elmnt.scrollIntoView(); }

  $('.flatpickr-date').flatpickr({
    altInput: true,
    altFormat: "F j, Y",
    dateFormat: "Y-m-d",
    maxDate: "today"
  });
  
  // Get all "navbar-burger" elements
  var $navbarBurgers = Array.prototype.slice.call(document.querySelectorAll('.navbar-burger'), 0); // Check if there are any navbar burgers

  if ($navbarBurgers.length > 0) {
    // Add a click event on each of them
    $navbarBurgers.forEach(function (el) {
      el.addEventListener('click', function () {
        // Get the target from the "data-target" attribute
        var target = el.dataset.target;
        var $target = document.getElementById(target); // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"

        el.classList.toggle('is-active');
        $target.classList.toggle('is-active');
      });
    });
  }

});
