import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "input", "submit", "edit", "cancel"]

  edit() {
    this.inputTargets.forEach(el => el.readOnly = !el.readOnly)
    this.editTarget.classList.toggle('display-none')
    this.submitTarget.classList.toggle('display-none')
    this.cancelTarget.classList.toggle('display-none')
  }

  cancelEdit() {
    this.inputTargets.forEach(el => {
      let initialValue = el.getAttribute('data-initial-value')
      if (el.type == "checkbox") {
        el.checked = initialValue
      } else {
        el.value = initialValue
      }
    })

    this.inputTargets.forEach(el => el.readOnly = !el.readOnly)
    this.editTarget.classList.toggle('display-none')
    this.submitTarget.classList.toggle('display-none')
    this.cancelTarget.classList.toggle('display-none')
  }
}
