"use strict";

function addTextTemplate(template) {
    const { editor } = document.querySelector('trix-editor')
    editor.recordUndoEntry('Content updated')
    editor.insertHTML(template)
  };

document.addEventListener('turbolinks:load', function () {
  $('.rx-template').click( function() {

    // NOTE:
    //  If this template changes, update encounter_factory's "rx_template" trait to match this.
    //  MedicationReportGenerator may need to be updated as well.
    var rxContent= `

    <br><u>Rx Dispensed</u></p>

    <p><strong>Medication</strong>: *</p>

    <p><strong>Strength</strong>: *</p>

    <p><strong>Dose, Route, Frequency</strong>: *</p>

    <p><strong>Duration</strong>: *</p>

    <p><strong>Quantity Dispensed</strong>: *</p>

    <p><strong>Lot Number</strong>: *</p>

    <p><strong>Expiration Date</strong>: *</p>

    <p><strong>Info Sheet Given&nbsp;</strong>(Yes/No): *</p>
  `
  addTextTemplate(rxContent);

  });
});
