document.addEventListener('turbolinks:load', function () {

  var search = (q) => {
    $.ajax({
      url: "/patients/search",
      type: "post",
      data: {"search_content": q} ,
      success: function(data) { 
        $('#search-results').html(data.html);
        $('#search-results').show();
        }
      }
    )
  }

  $('#search').on("input", function() { 
    let q = $('#search').val()
    if (q == "") {
      $('#search-results').html("");
      $('#search-results').hide();
    } else {
      search(q);
    }
  });

});
