import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "newGender" ]

  showInput(event) {
    if(event.target.options[event.target.selectedIndex].text == 'Add an Option') {
      $('#new-gender').css('display', '')
    } else {
      $('#new-gender').css('display', 'none')
    }
  }
}
